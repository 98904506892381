<template v-if="profile == 2">
  <v-app id="inspire">
    <v-navigation-drawer
      v-if="
        [
          'welcomeOffers',
          'welcomeModel_pv',
          'showModel_pv',
          'showSession',
          'showEvaluation',
          'showContract',
          'showAttribution'
        ].indexOf($route.name) < 1
      "
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      :permanent="!$vuetify.breakpoint.lgAndDown"
    >
      <detected-speed style="max-height:.35rem" :width="$vuetify.breakpoint.width" class="absolute">
        <div slot="fast">
          <div class="online">
            <span>&nbsp;</span>
          </div>
        </div>
        <div slot="slow">
          <div class="offline">
            <span>&nbsp;</span>
          </div>
        </div>
      </detected-speed>
      <v-img :aspect-ratio="16 / 9" :src="require('@/assets/profile.jpg')">
        <v-row align="end" class="lightbox white--text pa-2 fill-height">
          <v-col v-if="profile == 1">
            REF:
            <small class="text-uppercase"> ADMIN-{{ user_data.username }}</small
            ><br />
            NOM : {{ user_data ? user_data.firstname : user_data.company.name }} <br />
          </v-col>
          <v-col v-if="profile == 3">
            REF:
            <small class="text-uppercase"> AC-{{ user_data.username }}</small
            ><br />
            NOM : {{ user_data ? user_data.firstname : user_data.company.name }} <br />
            PAYS: {{ user_data.country }}
          </v-col>
          <v-col v-if="profile == 2">
            <v-card class="bg-white pa-2 mb-2">
              Désignation : Candidat/Soumissionnaire <br />
              <v-divider class="ma-2"></v-divider>
              <small> <b>Secteur d'activité :</b></small
              ><br />
              <span v-for="el in user_data.activitys" :key="el.id">
                <small>{{ el.name }}</small> <br />
              </span>
            </v-card>
            <v-divider light></v-divider>
            REF:
            <small class="text-uppercase"> OP-{{ user_data.username }}</small
            ><br />
            NOM : {{ user_data ? user_data.firstname : user_data.company.name }} <br />
            PAYS: {{ user_data.country }}<br />
          </v-col>
          <v-col v-if="profile == 4">
            REF:
            <small class="text-uppercase"> COM-{{ user_data.username }}</small
            ><br />
            NOM : {{ user_data ? user_data.firstname : user_data.company.name }} <br />
            PAYS: {{ user_data.country }}
          </v-col>

        </v-row>
      </v-img>
      <v-list v-if="profile == 3">
        <template v-for="item in items">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="h6" v-bind="attrs" v-on="on">{{ item.text }}</span>
                    </template>
                    <span>{{ item.text }}</span>
                  </v-tooltip>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item exact v-else :key="item.text" link :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h6" v-bind="attrs" v-on="on">{{ item.text }}</span>
                </template>
                <span>{{ item.text }}</span>
              </v-tooltip>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-list v-if="profile == 1">
        <template v-for="item in adminRoutes">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="h6" v-bind="attrs" v-on="on">{{ item.text }}</span>
                  </template>
                  <span>{{ item.text }}</span>
                </v-tooltip>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item exact v-else :key="item.text" link :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h6" v-bind="attrs" v-on="on">{{ item.text }}</span>
                </template>
                <span>{{ item.text }}</span>
              </v-tooltip>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-list v-if="profile == 2">
        <template v-for="item in opRoutes">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="h6" v-bind="attrs" v-on="on">{{ item.text }}</span>
                  </template>
                  <span>{{ item.text }}</span>
                </v-tooltip>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item exact v-else :key="item.text" link :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h6" v-bind="attrs" v-on="on">{{ item.text }}</span>
                </template>
                <span>{{ item.text }}</span>
              </v-tooltip>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-list v-if="profile == 4">
        <template v-for="item in comRoutes">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="h6" v-bind="attrs" v-on="on">{{ item.text }}</span>
                  </template>
                  <span>{{ item.text }}</span>
                </v-tooltip>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item exact v-else :key="item.text" link :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h6" v-bind="attrs" v-on="on">{{ item.text }}</span>
                </template>
                <span>{{ item.text }}</span>
              </v-tooltip>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="white" light>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.lgAndDown"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-divider vertical></v-divider>
      <v-toolbar-side-icon>
        <v-img src="@/assets/logo.png" height="50px" width="60px"> </v-img>
      </v-toolbar-side-icon>
      <v-divider vertical></v-divider>
      <v-toolbar-title class="ml-2">
        <span class="hidden-sm-and-down">ICP-E-PROCUREMENT</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon to="/">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on">
              mdi-home
            </v-icon>
          </template>
          <span>Accueil (Permet d’aller à la page d’accueil)</span>
        </v-tooltip>
      </v-btn>
      <v-divider vertical></v-divider>
      <v-btn icon to="/dashboard">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on">
              mdi-view-dashboard
            </v-icon>
          </template>
          <span> Tableau de bord (Permet de retourner à la page active de travail)</span>
        </v-tooltip>
      </v-btn>
      <!-- <v-btn icon to="message" v-if="profile == 3">
          <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-mailbox
        </v-icon>
      </template>
      <span>Envoie d'Email</span>
    </v-tooltip>
      </v-btn> -->
      <v-divider vertical></v-divider>
      <v-menu v-model="menu" :close-on-content-click="true" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn light icon class="mr-2" v-bind="attrs" v-on="on">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-account-circle
                </v-icon>
              </template>
              <span>Autres configurations pouvant être faite par l’utilisateur</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon small>mdi-account-circle</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                {{ user_data ? user_data.firstname : user_data.company.name }}
              </v-list-item-content>

              <v-list-item-action>
                <v-icon color="green darken-3">mdi-circle</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-list v-if="profile == 3">
            <template v-for="item in LogPop">
              <v-list-group
                v-if="item.children"
                :key="item.text"
                v-model="item.model"
                :prepend-icon="item.model ? item.icon : item['icon-alt']"
                append-icon
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-wrap">{{ item.text }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <v-list-item v-for="(child, i) in item.children" :key="i" link>
                  <v-list-item-action v-if="child.icon">
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ child.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-list-item exact v-else :key="item.text" link :to="item.route">
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="h6" v-bind="attrs" v-on="on">{{ item.text }}</span>
                    </template>
                    <span>{{ item.text }}</span>
                  </v-tooltip>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <v-list v-if="profile == 4 || profile == 2">
            <template v-for="item in OtherPop">
              <v-list-group
                v-if="item.children"
                :key="item.text"
                v-model="item.model"
                :prepend-icon="item.model ? item.icon : item['icon-alt']"
                append-icon
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <v-list-item v-for="(child, i) in item.children" :key="i" link>
                  <v-list-item-action v-if="child.icon">
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ child.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-list-item exact v-else :key="item.text" link :to="item.route">
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="h6" v-bind="attrs" v-on="on">{{ item.text }}</span>
                    </template>
                    <span>{{ item.text }}</span>
                  </v-tooltip>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <v-card-actions>
            <v-btn @click="logOut" color="red darken-3" text block>
              <span>Se déconnecter</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main class="bg">
      <v-container fluid ma-0 pa-0 fill-height class="bg">
        <v-row class="pa-2"></v-row>
        <v-layout
          :class="{
            'px-0 py-0': $vuetify.breakpoint.smAndDown,
            'px-5 py-1': $vuetify.breakpoint.mdAndUp
          }"
          child-flex
        >
          <v-expand-transition>
            <div>
              <v-row class="text-left my-2" align="start" justify="left">
                <v-breadcrumbs :items="breadCrumbs" divider=">">
                  <template v-slot:item="props">
                    <router-link :to="props.item.href" v-if="!props.item.disabled">
                      <v-breadcrumbs-item :class="[props.item.disabled && 'disabled']">
                        {{ props.item.text.toUpperCase() }}
                      </v-breadcrumbs-item>
                    </router-link>
                    <v-breadcrumbs-item :class="[props.item.disabled && 'disabled']" v-else>
                      {{ props.item.text }}
                    </v-breadcrumbs-item>
                  </template>
                </v-breadcrumbs>
              </v-row>

              <router-view :key="this.$route.path"></router-view>
            </div>
          </v-expand-transition>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DetectedSpeed from "vue-identify-network";

export default {
  components: {
    DetectedSpeed
  },
  data: () => ({
    user: null,
    profile: 0,
    user_data: [],
    fav: true,
    menu: false,
    message: false,
    hints: true,
    dialog: false,
    drawer: null,
    LogPop: [
      {
        icon: "mdi-briefcase-account-outline",
        text: "Configuration des membres de la commission d’ouverture, d’évaluation (Offres /Propositions /Plis) et de négociation",
        route: { name: "commission" }
      },
      {
        icon: "mdi-file-document-multiple",
        text: "Consulter, télécharger et répondre aux demandes d’éclaircissements",
        route: { name: "request" }
      },
      {
        icon: "mdi-account-supervisor-circle",
        text: "Programmation, invitation et publication du PV des visites de sites",
        route: { name: "visit" }
      },
      { icon: "mdi-devices",
       text: "Programmation, invitation et publication du PV des conférences préalables ",
        route: { name: "meeting" } 
      },
      { 
        icon: "mdi-forum",
        text: "Réception et réponses aux demandes de  renseignements (Contacts)",
        route: { name: "message" }
       },
      {
        icon: "mdi-account-cog",
        text: "Configuration, modification et mise à jour des informations sur la page d’accueil",
        route: { name: "Config" }
      },
      {
        icon: "mdi-alpha-m-box",
        text: "Création, modification et mise à jour des modèles de dossiers (PV d’ouverture, Dossiers d’Appel d’Offres, Demandes de Propositions, Rapports d’évaluation)",
        route: { name: "Model_pv" }
      }
      // { icon: "mdi-email-multiple", text: "Lettre d'invitation", route: { name: "Config" } }
    ],
    items: [
      {
        icon: "mdi-clipboard-file",
        text: "Création des Plans de Passation des Marchés",
        route: { name: "ppm" }
      },
      { icon: "mdi-bulletin-board", text: "Création des Avis d’Appel à la Concurrence", route: { name: "notice" } },
      {
        icon: "mdi-file-document-multiple-outline",
        text: " Création des dossiers de consultation (Appel d’Offres / Demande de Propositions / Sollicitation de Prix)",
        route: { name: "folder" }
      },
      {
        icon: "mdi-toolbox-outline",
        text: "Offres /Propositions /Plis ou Réponses aux Avis d’Appel à la Concurrence reçus (consulter et ou télécharger)",
        route: { name: "Offers" }
      },
      {
        icon: "mdi-server-security",
        text: "Création des séances publiques d’ouverture (Offres /Propositions /Plis)",
        route: { name: "Session" }
      },
      {
        icon: "mdi-card-bulleted-settings",
        text: "Création des séances d’évaluation (Offres /Propositions /Plis)",
        route: { name: "Evaluation" }
      },
      {
        icon: "mdi-ticket",
        text: "Création des séances plénières d’attribution des marchés",
        route: { name: "Attribution" }
      },
      {
        icon: "mdi-book-education-outline",
        text: " Transmission des projets de marchés/contrats / des PV de négociation aux consultants retenus",
        route: { name: "Contract" }
      }
    ],
    adminRoutes: [
      {
        icon: "mdi-account-plus",
        text: "Création de compte utilisateurs",
        route: { name: "UserAdd" }
      },
      { icon: "mdi-account-search", text: "Gestion des comptes utilisateurs", route: { name: "All_users" } },
      {
        icon: "mdi-file-document-multiple-outline",
        text: "Gestion des accès par rapport aux dossiers payants",
        route: { name: "OpFolder" }
      },
      {
        icon: "mdi-account-key-outline",
        text: "Abréviation/Acronyme",
        route: { name: "Access_management" }
      }
    ],
    OtherPop: [
      // {
      //   icon: "mdi-message-video",
      //   text: "Interface de reunion",
      //   route: { name: "confCall" }
      // },
    ],
    comRoutes: [
      {
        icon: "mdi-file-document-multiple-outline",
        text: "Consultation et téléchargement des dossiers de consultation (Appel d’Offres / Demande de Propositions / Sollicitation de Prix)",
        route: { name: "OpFolder" }
      },
      { 
        icon: "mdi-toolbox-outline", 
        text: "Consultation des Offres /Propositions /Plis ou Réponses aux Avis d’Appel à la Concurrence reçus", 
        route: { name: "Offers" } },
      { 
        icon: "mdi-server-security",
        text: "Consultation et participation à des séances publiques d’ouverture (Offres /Propositions /Plis)", 
        route: { name: "Session" } },
      {
        icon: "mdi-card-bulleted-settings",
        text: "Consultation et participation à des séances d’évaluation (Offres /Propositions /Plis)",
        route: { name: "Evaluation" }
      },
      { 
        icon: "mdi-ticket", 
        text: "Consultation et participation à des séances plénières d’attribution des marchés", 
        route: { name: "Attribution" } },
      {
        icon: "mdi-book-education-outline",
        text: "Consultation et participation à des séances de négociations des marchés/contrat.",
        route: { name: "Contract" }
      }
    ],
    opRoutes: [
      {
        icon: "mdi-file-document-multiple-outline",
        text: "Consultation et téléchargement des dossiers de consultation (Appel d’Offres / Demande de Propositions / Sollicitation de Prix)",
        route: { name: "OpFolder" }
      },
      { 
        icon: "mdi-toolbox-outline", 
        text: "Transmission des Offres /Propositions /Plis ou Réponses aux Avis d’Appel à la Concurrence", 
        route: { name: "Offers" } },
      {
        icon: "mdi-file-document-multiple",
        text: "Consultations et téléchargements des demandes d’éclaircissements",
        route: { name: "request" }
      },
      { icon: "mdi-account-supervisor-circle", text: "Consultation des visites de sites et téléchargement des Procès-Verbaux", route: { name: "visit" } },
      { icon: "mdi-devices", text: "Consultation et téléchargement des Procès-Verbaux des conférences préalables", route: { name: "meeting" } },
      { icon: "mdi-server-security", text: "Consultation des Procès-Verbaux des séances publiques d’ouvertures (Offres /Propositions /Plis)", route: { name: "Session" } },
      {
        icon: "mdi-book-education-outline",
        text: "Consultation et participation à des séances de négociations des marchés/contrats",
        route: { name: "Contract" }
      }
    ]
  }),
  created() {
    this.$store.dispatch("Auth/getAllConfigHome");
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
  },
  computed: {
    ...mapGetters({
      infoHome: "Auth/get_all_cnfig_home"
    }),
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      console.log(pathArray);
      pathArray.shift();
      const breadCrumbs = [];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      let lastIndexFound = 0;
      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
        console.log(
          this.$route,
          Object.hasOwnProperty.call(this.$route.matched[i], "meta"),
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadcrumb")
        );
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadcrumb")
        ) {
          console.log(pathArray[i - (i - lastIndexFound)]);
          breadCrumbs.push({
            href:
              i !== 0 && pathArray[i - (i - lastIndexFound)]
                ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
                : breadcrumb,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadcrumb || pathArray[i]
          });
          lastIndexFound = i;
          breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  },
  methods: {
    ...mapActions({
      logOut: "Auth/logOut"
    })
  }
};
</script>
<style>
.bg {
  background-color: #eae7fd;
}
</style>
