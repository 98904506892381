<template>
  <v-container fluid>
    <!-- <h1 class="mb-5">Tableau de board</h1> -->
    <v-row>
      <v-col>
        <detected-speed>
          <v-alert slot="unknown" dense outlined type="warning">
            <strong>Votre connexion est anormal</strong>
          </v-alert>
          <v-alert slot="slow" dense outlined type="error">
            <strong>Votre connexion n'est pas stable</strong>
          </v-alert>
          <v-alert slot="fast" dense outlined type="success">
            <strong>Votre connexion est stable</strong>
          </v-alert>
        </detected-speed>
      </v-col>
    </v-row>
    <v-row v-if="infoHome.data">
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card>
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="cyan fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 cyan--outlined">
              <h5 class="text-truncate text-uppercase">PPM</h5>
              <h1 v-text="infoHome.data.ppms"></h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card>
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="primary fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 primary--text">
              <h5 class="text-truncate text-uppercase">Avis</h5>
              <h1 v-text="infoHome.data.notices"></h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card>
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="success fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 success--text">
              <h5 class="text-truncate text-uppercase">Dossiers</h5>
              <h1 v-text="infoHome.data.folders"></h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card v-if="profile != 2 && profile != 4">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="indigo fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 indigo--text">
              <h5 class="text-truncate text-uppercase">Offres /Propositions /Plis</h5>
              <h1 v-text="infoHome.data.offers"></h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card v-if="profile != 2 && profile != 4">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="red fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 red--text">
              <h5 class="text-truncate text-uppercase">Additifs</h5>
              <h1 v-text="infoHome.data.extension_addons"></h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card v-if="profile != 2 && profile != 4">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="orange fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 orange--text">
              <h5 class="text-truncate text-uppercase">TDRs</h5>
              <h1 v-text="infoHome.data.extensions_tdrs"></h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card v-if="profile != 2 && profile != 4">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="blue fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 blue--text">
              <h5 class="text-truncate text-uppercase">Nombre d’inscrits</h5>
              <h1 v-text="infoHome.data.operators"></h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card v-if="profile != 2 && profile != 4">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="brown fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 brown--text">
              <h5 class="text-truncate text-uppercase">Membres de la commission</h5>
              <h1 v-text="infoHome.data.members"></h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!infoHome.data">
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card>
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="cyan fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 cyan--text">
              <h5 class="text-truncate text-uppercase">PPM</h5>
              <h1 v-text="0"></h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card>
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="primary fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 primary--text">
              <h5 class="text-truncate text-uppercase">Avis</h5>
              <h1 v-text="0"></h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card>
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="success fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 success--text">
              <h5 class="text-truncate text-uppercase">Dossiers</h5>
              <h1 v-text="0"></h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import DetectedSpeed from "vue-identify-network";

export default {
  components: {
    DetectedSpeed
  },
  data: () => ({}),
  created() {
    let Virt = [];
    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
    this.$store.dispatch("Auth/getAllConfigHome");
  },
  computed: {
    ...mapGetters({
      infoHome: "Auth/get_all_cnfig_home"
    })
  }
};
</script>
